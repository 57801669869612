<template>
  <el-form-item
    :label="
      showLabel ? (isDepositCurrency ? $t('common.field.depositCurrency') : $t('common.field.bankAccountCurrency')) : ''
    "
    prop="depositCurrency"
  >
    <el-select v-model="depositCurrency" id="depositCurrency" data-testid="depositCurrency">
      <el-option
        v-for="currency in supportedCurrencies"
        :key="currency"
        :value="currency"
        :label="currency"
        :data-testid="currency"
      ></el-option>
    </el-select>
  </el-form-item>
</template>
<script>
export default {
  name: 'DepositCurrency',
  props: {
    supportedCurrencies: Array,
    defaultCurrency: String,
    showLabel: {
      type: Boolean,
      default: true
    },
    isDepositCurrency: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    depositCurrency(currency) {
      this.$emit('setDepositCurrency', currency);
    }
  },
  data() {
    return {
      depositCurrency: this.defaultCurrency
    };
  }
};
</script>
