<template>
  <div class="content">
    <div class="info_box">
      <img :src="logo" alt="logo" />
      <p>
        {{
          $t('deposit.method.desc', {
            method: cryptoPayment.includes(paymentDetails.payment_method)
              ? paymentDetails.actual_currency_code
              : langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title)
          })
        }}
      </p>
      <ul v-if="cryptoPayment.includes(paymentDetails.payment_method)">
        <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
        <li>
          {{
            $t('deposit.crypto.inst2', {
              method: cryptoPayment.includes(paymentDetails.payment_method)
                ? paymentDetails.actual_currency_code
                : langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title)
            })
          }}
        </li>
        <li>
          {{
            $t('deposit.crypto.inst3', {
              method: cryptoPayment.includes(paymentDetails.payment_method)
                ? paymentDetails.actual_currency_code
                : langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title)
            })
          }}
        </li>
        <li>{{ $t('deposit.crypto.remark') }}</li>
      </ul>
      <ul v-else-if="bankTransferTemplate.includes(paymentDetails.payment_method)">
        <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.inst2', { number: '2', country: '' }) }}</li>
        <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
        <li>{{ $t('deposit.method.inst4', { number: '4' }) }}</li>
      </ul>
      <ul v-else-if="qrPaymentTemplate.includes(paymentDetails.payment_method)">
        <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.inst9', { number: '2' }) }}</li>
        <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
      </ul>
      <ul v-else-if="qrPaymentTemplate2.includes(paymentDetails.payment_method)">
        <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.ins11', { number: '2' }) }}</li>
        <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
      </ul>
      <ul v-else-if="fasapayTemplate.includes(paymentDetails.payment_method)">
        <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.inst5', { number: '2' }) }}</li>
        <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
        <li>{{ $t('deposit.method.inst10', { number: '4' }) }}</li>
      </ul>
      <ul v-else-if="alipayTemplate.includes(paymentDetails.payment_method)">
        <li>{{ $t('deposit.method.inst12', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.inst13', { number: '2' }) }}</li>
        <li>{{ $t('deposit.method.inst14', { number: '3' }) }}</li>
      </ul>
      <ul
        v-else-if="
          ['T00600', 'GCNT00600'].includes(paymentDetails.payment_method) &&
          this.paymentDetails.actual_currency_code == 'IDR'
        "
      >
        <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.inst2', { number: '2' }) }}</li>
        <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
        <li>{{ $t('deposit.method.inst4', { number: '4' }) }}</li>
      </ul>
      <ul
        v-else-if="
          thailandLBTTemplate.includes(paymentDetails.payment_method) &&
          this.paymentDetails.actual_currency_code == 'THB'
        "
      >
        <li>{{ $t('deposit.method.inst15', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.inst2', { number: '2' }) }}</li>
        <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
        <li>{{ $t('deposit.method.inst4', { number: '4' }) }}</li>
      </ul>
      <ul v-else>
        <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
        <li>{{ $t('deposit.method.inst3', { number: '2' }) }}</li>
      </ul>
    </div>
    <div class="form_main">
      <p class="title">
        {{
          $t('deposit.method.form.header', {
            method: langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title)
          })
        }}
      </p>
      <el-form label-position="top" :model="cpsForm" ref="cpsForm" status-icon :rules="cpsRules">
        <div class="form_box">
          <ul class="clearfix">
            <li class="fl">
              <AccountNumber
                :supportedCurrenciesList="tradeAccountCurrencies"
                :disabled="isDisabled"
                @setCurrency="setCurrency"
                @setAccountNumber="setAccountNumber"
              ></AccountNumber>
            </li>
            <li class="fr">
              <el-form-item :label="$t('common.field.amt')" prop="amount">
                <numeric-input
                  v-model="cpsForm.amount"
                  :readOnly="isDisabled"
                  :currency="accountCurrency"
                  :precision="2"
                ></numeric-input>
              </el-form-item>
            </li>
          </ul>

          <ul class="clearfix" v-if="bankCurrencySupport">
            <li class="fl">
              <DepositCurrency
                :supportedCurrencies="supportedDepositCurrencies"
                :isDepositCurrency="false"
                :defaultCurrency="cpsForm.depositCurrency"
                @setDepositCurrency="setDepositCurrency"
              ></DepositCurrency>
            </li>
          </ul>

          <!-- Attach Variables -->
          <ul class="clearfix" v-if="cpsForm.attachVariables.length > 0">
            <li :class="index % 2 !== 0 ? 'fr' : 'fl'" v-for="(type, index) in fieldList" :key="type.key">
              <!-- Input Field -->
              <DynamicRestrictInput
                :label="getFieldLabel(type.field_name)"
                :name="'attachVariables.' + index + '.value'"
                :testId="type.type"
                :restrictionType="type.restriction_type"
                v-model="fieldList[index].value"
                :maxlength="type.field_length"
                v-if="type.field_type == 'input_field'"
              ></DynamicRestrictInput>

              <!-- Dropdown List -->
              <el-form-item
                v-if="type.field_type == 'dropdown_list'"
                :label="$t('common.field.bankName')"
                :prop="'attachVariables.' + index + '.value'"
                :rules="selectRules"
              >
                <el-select
                  v-model="fieldList[index].value"
                  :disabled="isDisabled"
                  :placeholder="$t('common.field.select')"
                  :data-testid="type.key"
                >
                  <el-option
                    :label="value.bank_name"
                    :value="value.bank_code"
                    v-for="(value, name) in type.field_content"
                    :key="name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </li>
          </ul>

          <ul class="clearfix">
            <li class="fl">
              <el-form-item :label="$t('common.field.imptNotes')">
                <el-input v-model="cpsForm.notes" :disabled="isDisabled" data-testid="applicationNotes"></el-input>
              </el-form-item>
            </li>
            <li class="fr" v-if="showExchangeRates">
              <p class="mb-2">
                <span class="required_icon">*</span>
                {{
                  $t('deposit.default.rate.rate', {
                    oldCurrency: this.accountCurrency,
                    newCurrency: newCurrencyRate
                  })
                }}
                <span>{{ rate }}</span>
              </p>
              <p>
                <span class="required_icon">*</span> {{ paymentDetails.actual_currency_code }}:
                <span>{{ rateChange }}</span>
              </p>
            </li>
          </ul>
        </div>
        <el-button
          :loading="loading"
          type="primary"
          :disabled="isDisabled || loading"
          @click="submitCpsForm()"
          data-testid="submit"
        >
          {{ $t('common.button.submit') }}
        </el-button>
      </el-form>
      <div v-html="bankHTML"></div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import rounding from '@/util/rounding';
import AccountNumber from '@/components/form/AccountNumber';
import DepositCurrency from '@/components/form/DepositCurrency';
import DynamicRestrictInput from '@/components/form/DynamicRestrictInput';
import mixin from '@/mixins/page/deposit';
import cpsMixin from '@/mixins/cpsDeposit';
import { apiExchangeRate } from '@/resource';
import { apiCps_payment } from '@/resource/cps';
import { validateEmail } from '@/util/validation';
import fieldLabelList from '@/constants/depositFieldLabel';

export default {
  name: 'cps',
  components: { NumericInput, AccountNumber, DepositCurrency, DynamicRestrictInput },
  mixins: [mixin, cpsMixin],
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit, currency: this.accountCurrency })
          )
        );
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      bankHTML: '',
      logo: '',
      cpsForm: {
        accountNumber: '',
        amount: '',
        depositCurrency: 'USD',
        attachVariables: [],
        mandatory: [],
        notes: ''
      },
      selectRules: {
        required: true,
        message: this.$t('common.formValidation.common'),
        trigger: 'change'
      },
      emailRules: [
        {
          required: true,
          message: this.$t('common.formValidation.dynamicReq', {
            dynamic: this.$t('register.personalDetails.page1.email')
          }),
          trigger: 'blur'
        },
        {
          validator: validateEmail,
          trigger: 'blur'
        }
      ],
      inputRules: {
        required: true,
        message: this.$t('common.formValidation.common'),
        trigger: 'blur'
      },
      cpsRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      paymentChannel: '',
      exchangeRates: false,
      showExchangeRates: false,
      rate: 0.0,
      // maxLimit: Number(this.$store.state.cps.payment.highest_amount),
      minLimit: 50,
      accountCurrency: '',
      supportedDepositCurrencies: ['HKD', 'USD'],
      bankCurrencySupport: false,
      bankList: {}
    };
  },
  computed: {
    paymentDetails() {
      return this.$store.state.cps.currentPayment;
    },
    paymentTypes() {
      let paymentTypes = this.$store.state.cps.payment.child;
      return paymentTypes ?? [];
    },
    tradeAccountCurrencies() {
      let arr = [];
      let value = this.paymentDetails.merchant_variable?.trade_account;
      arr = value.split(',');
      return arr;
    },
    rateChange() {
      return rounding(Math.ceil, this.rate * this.cpsForm.amount, 2);
    },
    newCurrencyRate() {
      if (this.bankCurrencySupport) {
        return this.cpsForm.depositCurrency;
      }

      return this.paymentDetails.actual_currency_code;
    },
    fieldList() {
      return this.cpsForm.attachVariables.filter(el => {
        return el.field_type !== 'hidden_field';
      });
    }
  },
  watch: {
    accountCurrency: {
      immediate: true,
      handler() {
        this.dertermineBankCurrencySupport();
        this.determineExchangeRate();
        this.updateLimit();
      }
    },
    'cpsForm.depositCurrency': {
      immediate: true,
      handler() {
        this.determineExchangeRate(this.accountCurrency);
      }
    },
    rate() {
      this.updateLimit();
    },
    paymentDetails: {
      immediate: true,
      handler(val) {
        this.paymentLogo(this.paymentDetails.payment_method, this.paymentDetails.actual_currency_code);
        this.dertermineBankCurrencySupport();
        this.determineExchangeRate();
        this.updateLimit();
        this.setAttachVariables(val.attach_variable);
      }
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.cpsForm.accountNumber = accountNumber;
    },
    setCurrency(currency) {
      this.accountCurrency = currency;
    },
    success(msg) {
      window.location = msg;
    },
    determineExchangeRate() {
      // check if exchange rates is required
      if (!this.accountCurrency) {
        return;
      }

      if (this.paymentDetails.unique_currency) {
        if (this.bankCurrencySupport && this.accountCurrency !== this.cpsForm.depositCurrency) {
          this.showExchangeRates = true;
          this.exchangeRates = true;
          this.queryRate();
        } else {
          this.rate = 1;
          this.exchangeRates = false;
          this.showExchangeRates = false;
        }
      } else {
        if (
          (this.accountCurrency != this.paymentDetails.currency_code &&
            !this.cryptoPayment.includes(this.paymentDetails.payment_method)) ||
          (this.cryptoPayment.includes(this.paymentDetails.payment_method) && this.accountCurrency != 'USD')
        ) {
          if (!this.cryptoPayment.includes(this.paymentDetails.payment_method)) {
            this.showExchangeRates = true;
          }
          this.exchangeRates = true;
          this.queryRate();
        } else {
          this.rate = 1;
          this.exchangeRates = false;
          this.showExchangeRates = false;
        }
      }
    },
    submitCpsForm() {
      this.$refs['cpsForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              this.cpsHandling(result);
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    cpsHandling(result) {
      let channelData = JSON.parse(result.data.data);
      if (result.data.code == 0 && result.data.data) {
        this.bankHTML = channelData.payment_content;

        if (this.bankHTML) {
          this.redirectHandling();
        } else {
          this.loading = false;
          this.errorMessage(this.$t('deposit.default.failed'));
        }
      } else {
        if (result.data.code !== 562) this.errorMessage(this.$t('deposit.default.failed'));
        // re-calling anti-reuse token
        this.fetchToken()
          .then(resp => {
            this.loading = false;
          })
          .catch(resp => {
            this.loading = false;
            this.errorMessage(this.$t('resetPassword.failed'));
          });
      }
    },
    redirectHandling() {
      this.$nextTick(function () {
        if (document.getElementById('paysubmit') != null) {
          if (document.getElementById('paysubmit')) {
            document.getElementById('paysubmit').submit();
          } else {
            this.loading = false;
            this.errorMessage(this.$t('deposit.default.failed'));
          }
        }
      });
    },
    submitDeposit() {
      // get bank code index
      let bankIndex = this.cpsForm.attachVariables
        ? this.cpsForm.attachVariables.findIndex(function (type) {
            return type.key == 'bank_code';
          })
        : -1;

      // map attach variables
      let attachVariables = {};
      this.cpsForm.attachVariables.map(a => {
        attachVariables[a.key] = a.value;
      });

      const determineActualCurrencyCode = () => {
        if (this.bankCurrencySupport) {
          return this.cpsForm.depositCurrency;
        }

        return this.accountCurrency;
      };

      const getCurrencyNumber = (item, currency) => {
        if (item.unique_currency && currency) {
          const uniqueCurrencyConfig = item.unique_currency;
          let configObject = {};
          uniqueCurrencyConfig.forEach(config => {
            if (config.actual_currency_code === currency) {
              configObject = { ...config };
            }
          });
          return configObject.currency_number;
        }
        return item.currency_number;
      };

      const getActualCurrencyNumber = (item, currency) => {
        if (item.unique_currency && currency) {
          const uniqueCurrencyConfig = item.unique_currency;
          let configObject = {};
          uniqueCurrencyConfig.forEach(config => {
            if (config.actual_currency_code === currency) {
              configObject = { ...config };
            }
          });
          return configObject.actual_currency_number;
        }
        return item.actual_currency_number;
      };

      let requestBody = {
        mt4Account: this.cpsForm.accountNumber,
        operateAmount: this.cpsForm.amount,
        applicationNotes: this.cpsForm.notes,
        depositAmount: this.exchangeRates ? this.rateChange : this.cpsForm.amount,
        cpsCode: this.paymentDetails.payment_method,
        rate: this.rate,
        orderCurrency: this.paymentDetails.unique_currency
          ? getCurrencyNumber(this.paymentDetails, determineActualCurrencyCode())
          : this.paymentDetails.currency_number,
        actualCurrency: this.paymentDetails.unique_currency
          ? getActualCurrencyNumber(this.paymentDetails, determineActualCurrencyCode())
          : this.paymentDetails.actual_currency_number,
        mandatory: this.cpsForm.mandatory ? this.cpsForm.mandatory.toString() : [],
        attachVariables: JSON.stringify(attachVariables),
        bankCode: bankIndex != -1 ? this.cpsForm.attachVariables[bankIndex].value : ''
      };

      return apiCps_payment(requestBody, this.token);
    },
    queryRate() {
      let fromCurrency = this.accountCurrency;
      let toCurrency = '';

      if (this.cryptoPayment.includes(this.paymentDetails.payment_method)) {
        toCurrency = 'USD';
      } else if (this.bankCurrencySupport && this.paymentDetails.unique_currency) {
        toCurrency = this.cpsForm.depositCurrency;
      } else {
        toCurrency = this.paymentDetails.actual_currency_code;
      }

      apiExchangeRate({
        fromCurrency: fromCurrency,
        toCurrency: toCurrency
      })
        .then(resp => {
          if (resp.data.code == 0) {
            this.rate = resp.data.data;
          }
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', {
              oldCurrency: this.accountCurrency,
              newCurrency: this.cryptoPayment.includes(this.paymentDetails.payment_method)
                ? 'USD'
                : this.paymentDetails.actual_currency_code
            })
          );
        });
    },
    updateLimit() {
      let newLimit = false;

      if (!this.accountCurrency) {
        return;
      }

      if (this.paymentDetails.merchant_variable?.limit) {
        this.paymentDetails.merchant_variable?.limit.some(limit => {
          if (this.accountCurrency == limit.account_currency) {
            this.maxLimit = limit.max;
            this.minLimit = limit.min;
            newLimit = true;
            return true;
          }
        });
      }

      if (!newLimit) {
        this.maxLimit = 99999999.99;
        this.minLimit = 50.0;
      }
    },
    setDepositCurrency(currency) {
      this.cpsForm.depositCurrency = currency;
    },
    dertermineBankCurrencySupport() {
      // Support for Dollarsmart-FPS-USD
      if (this.paymentDetails.payment_method === 'T00600_067' && this.accountCurrency === 'USD') {
        this.bankCurrencySupport = true;
      } else {
        this.bankCurrencySupport = false;
      }
    },
    setAttachVariables(attach_variable) {
      if (attach_variable) {
        this.cpsForm.attachVariables = attach_variable.filter(f => f.hasOwnProperty('field_type'));
        this.cpsForm.mandatory = attach_variable.filter(f => f.length > 0)?.[0];
      }
    },
    getFieldLabel(val) {
      const i18nKey = this.getI18nKey(val);

      if (this.$te(i18nKey) || this.$te(i18nKey, 'en_US')) {
        if (i18nKey === 'common.field.paymentAccName' || i18nKey === 'common.field.paymentAccNum')
          return this.$t(i18nKey, { name: val.split(' ')[0] });
        return this.$t(i18nKey);
      }

      return val;
    },
    getI18nKey(val) {
      let label = fieldLabelList[val];
      return label ? label : val;
    }
  },
  mounted() {
    this.determineExchangeRate();
    // After a refresh, default activeIndex is not always set to 0.
    // Instead, currentPayment is used in place of payment.child[0]
    let attachVariables = Object.values(this.paymentDetails.attach_variable);
    // let attachVariables = Object.values(this.$store.state.cps.payment.child[0].attach_variable);

    // filter by attach variables key in object
    this.cpsForm.attachVariables = attachVariables.filter(f => f.hasOwnProperty('field_type'));

    // filter by mandatory key in array
    this.cpsForm.mandatory = attachVariables.filter(f => f.length > 0)?.[0];
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
