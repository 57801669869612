<template>
  <div id="cps">
    <div class="content_box">
      <div class="inner_max">
        <div class="page-header">
          {{ $t('menu.depositFund') }}
          <span v-if="isFromDepositFundsCps"> - (Special Page)</span>
        </div>
        <div class="form_deposit">
          <div v-if="isMultiplePaymentTypes" class="info_box_choose">
            <p>
              {{ $t('deposit.method.choose', { method: paymentDetails.description_title }) }}
            </p>
            <el-tooltip
              v-for="(item, index) in paymentTypes"
              class="item"
              effect="dark"
              :content="langTranslate('deposit.cpsInfo.', item.merchant_variable.label_off_desc)"
              placement="bottom-start"
              :disabled="getLabelSwitch(item.merchant_variable)"
              :key="item.index"
            >
              <el-button
                :class="elButtonClassName(index == activeIndex)"
                @click="handleSelectIndex(index)"
                :data-testid="item.index"
                :disabled="!getLabelSwitch(item.merchant_variable)"
              >
                <div class="button_icon_wrapper">
                  <span>{{ langTranslate('deposit.cpsInfo.', item.merchant_variable.label) }}</span>
                  <img
                    v-if="cryptoPayment.includes(item.payment_method)"
                    :src="paymentLogo(item.payment_method, item.actual_currency_code)"
                    alt="logo"
                  />
                </div>
              </el-button>
            </el-tooltip>
          </div>

          <!-- CPS Component -->
          <InternationalBank v-if="isIBT" />
          <CPS v-else :isDisabled="isDisabled" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CPS from '@/components/deposit/Cps/Index.vue';
import InternationalBank from '@/components/deposit/Cps/InternationalBank.vue';
import multiplePaymentMixin from '@/mixins/page/deposit/multiplePayment';
import cpsMixin from '@/mixins/cpsDeposit';
import { internationalBankData } from '@/constants/payment/depositSetting.json';

export default {
  name: 'cps',
  components: { CPS, InternationalBank },
  mixins: [multiplePaymentMixin, cpsMixin],
  data() {
    return {
      activeIndex: 0,
      isDisabled: false
    };
  },
  computed: {
    paymentDetails() {
      return this.$store.state.cps.payment;
    },
    currentSelectedPayment() {
      return this.$store.state.cps.currentPayment;
    },
    paymentTypes() {
      let paymentTypes = this.paymentDetails.child;
      return paymentTypes;
    },
    isMultiplePaymentTypes() {
      return this.paymentTypes.length > 1;
    },
    paymentMethod() {
      return this.paymentDetails.payment_method;
    },
    isIBT() {
      return internationalBankData.includes(this.paymentMethod);
    },
    isFromDepositFundsCps() {
      return this.$route.query.page === 'special';
    }
  },
  methods: {
    handleSelectIndex(index) {
      this.activeIndex = index;

      if (this.paymentTypes.length > 0) {
        let payment = this.paymentTypes[index];
        this.$store.dispatch('cps/actionSetCurrentPayment', payment);
      }
    },
    getLabelSwitch(merchantVariable) {
      if (typeof merchantVariable.label_switch === 'undefined') return true;
      return merchantVariable.label_switch;
    },
    setDefaultPayment() {
      if (this.paymentTypes.length > 0) {
        //get last selected payment index
        let paymentIndex = this.paymentTypes.findIndex(i => {
          return (
            i.merchant_variable.label == this.currentSelectedPayment.merchant_variable?.label &&
            this.getLabelSwitch(i.merchant_variable)
          );
        });
        // get first available payment if there is no default value
        if (paymentIndex == -1)
          paymentIndex = this.paymentTypes.findIndex(i => {
            return this.getLabelSwitch(i.merchant_variable);
          });
        if (paymentIndex == -1) {
          this.handleSelectIndex(0);
          this.isDisabled = true;
        } else {
          this.activeIndex = paymentIndex;
          this.handleSelectIndex(this.activeIndex);
        }
      }
    }
  },
  mounted() {
    this.setDefaultPayment();
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
@import '@/assets/css/components/deposit/multipleButton.scss';
</style>
