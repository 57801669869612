<template>
  <div id="internationalBank">
    <div v-if="formFlag">
      <h4 class="payment_title">
        {{
          langTranslate(
            'deposit.default.channels.',
            paymentDetails.merchant_variable.description_title || paymentDetails.merchant_variable.label
          )
        }}
      </h4>
      <el-form label-position="top" :model="cpsForm" ref="cpsForm" status-icon :rules="internationalRules">
        <div class="box_left box">
          <strong class="title"><span>1</span>{{ $t('deposit.default.deposit') }} </strong>
          <div class="box_left_content form_content_box">
            <div class="info mb-5">
              <p>
                {{
                  $t('deposit.method.desc', {
                    method: cryptoPayment.includes(paymentDetails.payment_method)
                      ? paymentDetails.actual_currency_code
                      : langTranslate(
                          'deposit.default.channels.',
                          paymentDetails.merchant_variable.description_title || paymentDetails.merchant_variable.label
                        )
                  })
                }}
              </p>
              <ul>
                <li>{{ $t('deposit.fxir.desc1', { number: '1' }) }}</li>
                <li>{{ $t('deposit.fxir.desc2', { number: '2' }) }}</li>
              </ul>
            </div>
            <el-row>
              <el-col :lg="24" :xl="12">
                <el-form-item :label="$t('common.field.accNum')" prop="accountNumber">
                  <el-select
                    v-model="cpsForm.account"
                    :no-data-text="$t('common.formValidation.noEligibleAccounts')"
                    data-testid="accountNumber"
                  >
                    <el-option
                      v-for="item in cpsForm.accountNumberOptions"
                      :key="item.value"
                      :value="item.value"
                      :data-testid="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="bank_info" v-if="isShow">
              <ul v-for="{ title, info } in bankInfo" :key="title">
                <li v-if="info.show">
                  <span class="title_name">{{ $t(title) }}</span>
                  <span class="value_info">{{ info.value }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="box_right box">
          <strong class="title"> <span>2</span>{{ $t('deposit.default.sendReceipt') }} </strong>
          <div class="box_right_content form_content_box">
            <div class="info">
              <p>{{ $t('deposit.fxir.desc3') }}</p>
              <p>{{ $t('deposit.intSwift.bussDayInfo') }}</p>
              <p>{{ $t('deposit.fxir.desc4') }}</p>
            </div>
            <div class="form_list">
              <ul class="clearfix">
                <li class="fl">
                  <el-form-item :label="$t('common.field.accNum')">
                    <div class="special_input">{{ cpsForm.accountNumber }}</div>
                  </el-form-item>
                </li>
                <li class="fr">
                  <el-form-item :label="$t('common.field.amt')" prop="amount">
                    <numeric-input v-model="cpsForm.amount" :currency="cpsForm.currency" :precision="2"></numeric-input>
                  </el-form-item>
                </li>
              </ul>

              <!-- Attach Variables -->
              <ul class="clearfix" v-if="cpsForm.attachVariables.length > 0">
                <li
                  :class="index % 2 !== 0 ? 'fr' : 'fl'"
                  v-for="(type, index) in cpsForm.attachVariables"
                  :key="type.key"
                >
                  <!-- Input Field -->
                  <DynamicRestrictInput
                    :label="getFieldLabel(type.field_name)"
                    :name="'attachVariables.' + index + '.value'"
                    :testId="type.type"
                    :restrictionType="type.restriction_type"
                    v-model="cpsForm.attachVariables[index].value"
                    :maxlength="type.field_length"
                    v-if="type.field_type == 'input_field'"
                  ></DynamicRestrictInput>

                  <!-- Dropdown List -->
                  <el-form-item
                    v-if="type.field_type == 'dropdown_list'"
                    :label="$t('common.field.bankName')"
                    :prop="'attachVariables.' + index + '.value'"
                    :rules="selectRules"
                  >
                    <el-select
                      v-model="cpsForm.attachVariables[index].value"
                      :placeholder="$t('common.field.select')"
                      :data-testid="type.key"
                    >
                      <el-option
                        :label="value.bank_name"
                        :value="value.bank_code"
                        v-for="(value, name) in type.field_content"
                        :key="name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </li>
              </ul>

              <!-- upload, notes -->
              <ul class="clearfix">
                <li class="fl">
                  <el-form-item :label="$t('common.field.upload')" prop="uploadFile">
                    <vUpload
                      :action="uploadURL"
                      :limit="6"
                      v-on:updateFileInfo="updateFileInfo"
                      data-testid="updateFileInfo"
                    ></vUpload>
                  </el-form-item>
                </li>
                <li class="fr">
                  <el-form-item :label="$t('common.field.notes')">
                    <el-input v-model="cpsForm.notes" data-testid="applicationNotes"></el-input>
                    <div class="warn_info p-1 mt-3 text-center text-magenta border border-magenta">
                      <p class="text-white" v-html="$t('deposit.intSwift.tip')"></p>
                    </div>
                  </el-form-item>
                </li>
              </ul>
            </div>
            <div class="button">
              <el-button
                type="primary"
                :loading="loading"
                :disabled="loading"
                @click="submitCpsForm()"
                data-testid="submit"
              >
                {{ $t('common.button.submit') }}
              </el-button>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <Result v-if="successFlag">{{ $t('deposit.default.successMsg') }}</Result>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import rounding from '@/util/rounding';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import mixin from '@/mixins/page/deposit';
import DynamicRestrictInput from '@/components/form/DynamicRestrictInput';
import cpsMixin from '@/mixins/cpsDeposit';
import { apiQuery_mt_accounts, apiExchangeRate } from '@/resource';
import { apiCps_payment } from '@/resource/cps';
import fieldLabelList from '@/constants/payment/depositFieldLabel';

export default {
  name: 'internationalBank',
  components: { vUpload, NumericInput, Result, DynamicRestrictInput },
  mixins: [mixin, cpsMixin],
  data() {
    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) callback(new Error(this.$t('common.formValidation.fileReceipt')));
      else callback();
    };
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit, currency: this.accountCurrency })
          )
        );
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      cpsForm: {
        account: '',
        accountNumberOptions: [],
        amount: '',
        accountNumber: '',
        currency: '',
        notes: '',
        mandatory: [],
        attachVariables: []
      },
      internationalRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            trigger: 'change'
          }
        ]
      },
      minLimit: this.$platform.depositOneAmountMin,
      rate: 0.0,
      fileList: [],
      ibtBank: null,
      isShow: false,
      formFlag: true,
      successFlag: false,
      uploadURL: '/api/file/cps-upload'
    };
  },
  watch: {
    'cpsForm.account'(value) {
      const values = value.split(' - ');
      this.cpsForm.accountNumber = values[0];
      this.cpsForm.currency = values[1];
      this.accountCurrency = values[1];
      this.isShow = true;
      this.determineExchangeRate();
    },
    'cpsForm.accountNumberOptions'(options) {
      // 預設第一筆
      if (options.length > 0) this.cpsForm.account = options[0].value;
    },
    'cpsForm.currency'(value) {
      this.updateLimit();
    }
  },
  methods: {
    getFieldLabel(val) {
      const i18nKey = this.getI18nKey(val);
      if (this.$te(i18nKey) || this.$te(i18nKey, 'en_US')) {
        if (i18nKey === 'common.field.paymentAccName' || i18nKey === 'common.field.paymentAccNum')
          return this.$t(i18nKey, { name: val.split(' ')[0] });
        return this.$t(i18nKey);
      }
      return val;
    },
    getI18nKey(val) {
      let label = fieldLabelList[val];
      return label ? label : val;
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['cpsForm'].validateField('uploadFile');
    },
    submitCpsForm() {
      this.$refs['cpsForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              this.cpsHandling(result);
            })
            .catch(err => {
              console.error('submitCpsForm err', err);
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    cpsHandling(result) {
      if (result.data.code == 0 && result.data.data) {
        this.formFlag = false;
        this.successFlag = true;
      } else {
        if (result.data.code !== 562) this.errorMessage(this.$t('deposit.default.failed'));
        // re-calling anti-reuse token
        this.fetchToken()
          .then(resp => {
            this.loading = false;
          })
          .catch(resp => {
            this.loading = false;
            this.errorMessage(this.$t('resetPassword.failed'));
          });
      }
    },
    fetchTradingAccount() {
      apiQuery_mt_accounts({ supportedCurrencies: 'all' }).then(resp => {
        if (resp.data.code == 0) {
          this.cpsForm.accountNumberOptions = resp.data.data
            .filter(({ currency }) => this.supportedCurrencies(currency))
            .map(({ mt4_account, currency }) => {
              return {
                accountNumber: mt4_account,
                value: mt4_account + ' - ' + currency,
                currency: currency
              };
            });
        }
      });
    },
    supportedCurrencies(accountCurrency) {
      const defaultCurrencies = ['USD', 'CAD', 'EUR', 'GBP', 'NZD', 'SGD', 'JPY', 'HKD'];
      const restrictCountry = [
        6976, //Jordan
        3389, //Oman
        3636, //Brazil
        6777, // Italy
        6907 // United Kingdom
      ];

      switch (true) {
        case restrictCountry.includes(parseInt(this.countryCode)):
          return ['AUD', 'USD', 'CAD', 'EUR', 'GBP', 'NZD', 'SGD', 'JPY', 'HKD'].includes(accountCurrency);
        case [3382].includes(parseInt(this.countryCode)):
          return ['USD', 'CAD', 'EUR', 'GBP', 'NZD', 'SGD', 'JPY', 'HKD', 'BRL'].includes(accountCurrency);
        case [1].includes(parseInt(this.countryCode)):
          return ['USD', 'EUR', 'GBP', 'HKD'].includes(accountCurrency);
        // Iran FX-IR
        case [6766].includes(parseInt(this.countryCode)):
          return ['USD'].includes(accountCurrency);
        default:
          return defaultCurrencies.includes(accountCurrency);
      }
    },
    determineExchangeRate() {
      // check if exchange rates is required
      if (!this.accountCurrency) {
        return;
      }

      if (this.accountCurrency != this.paymentDetails.currency_code) {
        if (!this.cryptoPayment.includes(this.paymentDetails.payment_method)) {
          this.showExchangeRates = true;
        }
        this.exchangeRates = true;
        this.queryRate();
      } else {
        this.rate = 1;
        this.exchangeRates = false;
        this.showExchangeRates = false;
      }
    },
    queryRate() {
      let fromCurrency = this.accountCurrency;
      let toCurrency = '';

      if (this.cryptoPayment.includes(this.paymentDetails.payment_method)) {
        toCurrency = 'USD';
      } else if (this.bankCurrencySupport && this.paymentDetails.unique_currency) {
        toCurrency = this.cpsForm.depositCurrency;
      } else {
        toCurrency = this.paymentDetails.actual_currency_code;
      }

      apiExchangeRate({
        fromCurrency: fromCurrency,
        toCurrency: toCurrency
      })
        .then(resp => {
          if (resp.data.code == 0) {
            this.rate = resp.data.data;
          }
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', {
              oldCurrency: this.accountCurrency,
              newCurrency: this.cryptoPayment.includes(this.paymentDetails.payment_method)
                ? 'USD'
                : this.paymentDetails.actual_currency_code
            })
          );
        });
    },
    submitDeposit() {
      let bankIndex = this.cpsForm.attachVariables
        ? this.cpsForm.attachVariables.findIndex(function (type) {
            return type.key == 'bank_code';
          })
        : -1;

      let attachVariables = {};
      if (this.cpsForm.attachVariables) {
        this.cpsForm.attachVariables.map(a => {
          if (a.field_type === 'read_only') attachVariables['ibt_bank'] = this.ibtBank;
          else if (a.key === 'file_path') attachVariables[a.key] = this.convertToAttachVariablesFileList(this.fileList);
          else attachVariables[a.key] = a.value;
        });
      }
      const determineActualCurrencyCode = () => {
        return this.accountCurrency;
      };

      const getCurrencyNumber = (item, currency) => {
        if (item.unique_currency && currency) {
          const uniqueCurrencyConfig = item.unique_currency;
          let configObject = {};
          uniqueCurrencyConfig.forEach(config => {
            if (config.actual_currency_code === currency) {
              configObject = { ...config };
            }
          });
          return configObject.currency_number;
        }
        return item.currency_number;
      };

      const getActualCurrencyNumber = (item, currency) => {
        if (item.unique_currency && currency) {
          const uniqueCurrencyConfig = item.unique_currency;
          let configObject = {};
          uniqueCurrencyConfig.forEach(config => {
            if (config.actual_currency_code === currency) {
              configObject = { ...config };
            }
          });
          return configObject.actual_currency_number;
        }
        return item.actual_currency_number;
      };

      let requestBody = {
        mt4Account: this.cpsForm.accountNumber,
        operateAmount: this.cpsForm.amount,
        applicationNotes: this.cpsForm.notes,
        depositAmount: this.exchangeRates ? this.rateChange : this.cpsForm.amount,
        cpsCode: this.paymentDetails.payment_method,
        rate: this.rate,
        orderCurrency: this.paymentDetails.unique_currency
          ? getCurrencyNumber(this.paymentDetails, determineActualCurrencyCode())
          : this.paymentDetails.currency_number,
        actualCurrency: this.paymentDetails.unique_currency
          ? getActualCurrencyNumber(this.paymentDetails, determineActualCurrencyCode())
          : this.paymentDetails.actual_currency_number,
        mandatory: this.cpsForm.mandatory ? this.cpsForm.mandatory.toString() : [],
        attachVariables: JSON.stringify(attachVariables),
        bankCode: bankIndex != -1 ? this.cpsForm.attachVariables[bankIndex].value : '',
        fileList: this.fileList
      };

      return apiCps_payment(requestBody, this.token);
    },
    updateLimit() {
      let newLimit = false;

      if (!this.cpsForm.currency) {
        return;
      }

      if (this.paymentDetails.merchant_variable?.limit) {
        this.paymentDetails.merchant_variable?.limit.some(limit => {
          if (this.cpsForm.currency == limit.account_currency) {
            this.maxLimit = limit.max;
            this.minLimit = limit.min;
            newLimit = true;
            return true;
          }
        });
      }

      if (!newLimit) {
        this.maxLimit = 99999999.99;
        this.minLimit = 50.0;
      }
    },
    setBankInfo(attachVariables) {
      if (!attachVariables) return;
      const data = attachVariables.filter(({ field_type }) => field_type === 'read_only')[0].field_content[0];
      this.ibtBank = data.value;
    },
    getTranslation(val) {
      if (!val) return;
      return translation[val.value] ? this.$t(translation[val.value]) : val.value;
    },
    convertToAttachVariablesFileList(list) {
      return list.map(el => {
        let url = new URL(el);
        return url.pathname;
      });
    }
  },
  mounted() {
    this.fetchTradingAccount();
    this.determineExchangeRate();
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    paymentDetails() {
      let attachVariables = Object.values(this.$store.state.cps.payment.child[0].attach_variable);
      this.cpsForm.attachVariables = attachVariables.filter(field => field.hasOwnProperty('field_type'));
      this.setBankInfo(this.cpsForm.attachVariables);
      this.cpsForm.mandatory = attachVariables.filter(field => field.length > 0)?.[0];
      return this.$store.state.cps.currentPayment;
    },
    bankInfo() {
      return this.paymentDetails.attach_variable
        .flatMap(({ field_content }) => field_content ?? [])
        .flatMap(({ field_content }) => {
          return (
            field_content.map(({ name, value }) => ({
              title: this.getFieldLabel(name),
              info: { show: true, value }
            })) ?? []
          );
        });
    },
    rateChange() {
      return rounding(Math.ceil, this.rate * this.cpsForm.amount, 2);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/internationalAndAu.scss';
@import '@/assets/css/pages/deposit/deposit.scss';
.form_deposit {
  .payment_title {
    text-align: center;
  }
}

.info {
  li {
    margin-bottom: 15px;
    color: $white;
  }
}
</style>
